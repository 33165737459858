<template src="./index.html" />

<script>
import * as XLSX from 'xlsx'
import {
  BBreadcrumb, BButton, BFormFile, BCard, BCol, BContainer, BFormSelect, BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import { mapActions, mapGetters } from 'vuex'
import { Workbook } from '@syncfusion/ej2-excel-export'

export default {
  name: 'ImportStudentCreditClass',
  components: {
    BBreadcrumb,
    BButton,
    BFormFile,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    XLSX,
    VueGoodTable,
    ToastificationContent,
  },

  props: {
    dataSend: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      table: {
        fields: [
          {
            field: 'stt', label: 'STT', sortable: false, type: 'string',
          },
          {
            field: 'code', label: 'Mã sinh viên', sortable: false, type: 'string',
          },
          {
            field: 'className', label: 'Tên lớp độc lập', sortable: false, type: 'string',
          },
        ],
        items: [],
      },
      tenFile: '',
      dataExcel: [],
      dataExcelHandle: [],
      arrCode: [],
      dataDuplicate: [],
      totalRecords: 0,
      serverParams: {
        page: 1,
        perPage: 10,
      },
      loading: false,
    }
  },

  computed: {
    ...mapGetters({
      total: 'rooms/total',

    }),
    // calculate data from init data
  },

  watch: {
    // listening change data init

  },
  methods: {
    ...mapActions({
      importStudentCreditClass: 'creditClass/importStudentCreditClass',
    }),
    async importExcel(e) {
      const { files } = e.target
      if (!files.length) {
        return
      } if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        // show err
      }
      if (files[0].name) {
        this.tenFile = files[0].name
      }
      this.readFile(files)
    },

    readFile(files) {
      const fileReader = new FileReader()
      fileReader.onload = async ev => {
        try {
          const data = ev.target.result
          const workbook = XLSX.read(data, {
            type: 'binary',
          })
          const wsname = workbook.SheetNames[0]
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
            header: 0,
            defval: '',
          })
          const excellist = []
          // Edit data
          if (ws.length > 0) {
            for (let i = 0; i < ws.length; i++) {
              excellist.push(ws[i])
            }
          } else {
            this.showToast('File không có dữ liệu', 'XCircleIcon', 'danger', '')
            return
          }
          if (
            !excellist[0].hasOwnProperty('MA_SINH_VIEN')
          ) {
            this.showToast('File chưa đúng định dạng', 'XCircleIcon', 'danger', '')
            return
          }

          this.dataExcel = []
          const tempArr = []
          for (let k = 0; k < excellist.length; k++) {
            const item = excellist[k]
            const obj = {
              stt: k + 1,
              code: item.MA_SINH_VIEN,
              className: this.dataSend.subjectName,
              classCode: item.MA_LOP_CO_DINH,
            }
            this.dataExcel.push(obj)
            this.arrCode.push(item.MA_SINH_VIEN)
            if (!tempArr.includes(item.MA_SINH_VIEN)) {
              tempArr.push(item.MA_SINH_VIEN)
              this.dataExcelHandle.push(obj)
            }
          }
          this.table.items = this.dataExcel
          this.dataDuplicate = this.toFindDuplicates(this.arrCode)
          this.totalRecords = this.dataExcel.length
          if (this.totalRecords > 10) {
            this.table.items = this.dataExcel.slice(0, 10)
          }
          // insert ban ghi
        } catch (e) {
          alert(`Read failure!${e}`)
        }
      }
      fileReader.readAsBinaryString(files[0])
    },
    toFindDuplicates(array) {
      return array.filter((item, index) => array.indexOf(item) !== index)
    },
    resetInputFile() {
      this.$refs.inputFileRef.reset()
    },
    async importStudent() {
      const data = this.dataExcelHandle
      if (this.dataDuplicate.length > 0) {
        this.showToast('Vui lòng loại bỏ các dữ liệu bị trùng !', 'XCircleIcon', 'danger', '')
        return
      }

      if (data.length + this.dataSend.currentQty > this.dataSend.maxQty) {
        this.$swal({
          title: 'Sau khi import số lương sinh viên có thể lớn hơn số lượng tối đa lớp độc lập',
          text: 'Bạn vẫn muốn thực hiện thao tác này!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
          cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(async result => {
          if (result.value) {
            await this.handleImportStudentCreditClass(data)
          }
        })
      } else {
        await this.handleImportStudentCreditClass(data)
      }
    },

    async handleImportStudentCreditClass(data) {
      this.loading = true
      try {
        const response = await this.importStudentCreditClass({
          creditClassId: this.dataSend.id,
          params: data,
        })
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.resetInputFile()
            this.$emit('onSuccessModal', 'modal-import-student')
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.loading = false
      }
    },

    removeDuplicate() {
      this.dataDuplicate = []
      this.table.items = this.dataExcelHandle
      this.totalRecords = this.dataExcelHandle.length
      if (this.totalRecords > 10) {
        this.table.items = this.dataExcelHandle.slice(0, 10)
      }
    },

    downloadFile() {
      const columns = [
        { index: 1, width: 130 },
        { index: 2, width: 200 },
      ]
      const rows = []
      const headerRow = {
        index: 1,
        cells: [
          {
            index: 1,
            value: 'MA_SINH_VIEN',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 2,
            value: 'MA_LOP_CO_DINH',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
        ],
      }

      rows.push(headerRow)
      const worksheets = [{ columns, rows }]
      const workbook = new Workbook({ worksheets }, 'xlsx')

      workbook.save('export_lop_doc_lap.xlsx')
    },
    // handle even

    showToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },

    onPageChange(params) {
      const start = params.currentPerPage * (params.currentPage - 1)
      const end = this.dataExcel.length > (params.currentPerPage * params.currentPage) ? (params.currentPerPage * params.currentPage) : this.dataExcel.length
      this.table.items = this.dataExcel.slice(start, end)
    },

    onPerPageChange(params) {
      const start = params.currentPerPage * (params.currentPage - 1)
      const end = this.dataExcel.length > (params.currentPerPage * params.currentPage) ? (params.currentPerPage * params.currentPage) : this.dataExcel.length
      this.table.items = this.dataExcel.slice(start, end)
    },

  },
}
</script>
