<template>
  <validation-observer
    ref="creditClassRoomSaveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="creditClassRoomSaveModal"
      body-class="position-static"
      centered
      no-close-on-backdrop
      size="lg"
      :title="isCreated ? `Thêm ${resourceName.fullName}` : `Cập nhật ${resourceName.fullName}`"
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group label-for="buildingId">
              <template v-slot:label>
                Tòa nhà <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Tòa nhà"
                rules="required"
              >
                <v-select
                  v-model="targetData.buildingId"
                  :options="buildings"
                  :reduce="option => option.value"
                  @input="onBuildingChange"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group label-for="roomId">
              <template v-slot:label>
                Phòng <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{errors}"
                name="Phòng"
                rules="required"
              >
                <v-select
                  v-model="targetData.roomId"
                  :options="rooms"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('creditClassRoomSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BForm, BFormGroup, BModal, BOverlay, BFormInvalidFeedback, BButton, BRow, BCol,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import * as events from 'events'
import { getUser } from '@/auth/utils'

export const Attr = Object.freeze({
  store: 'creditClassRoom',
})
export default {
  name: 'CreditClassRoomSave',
  components: {
    BFormGroup,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    mainData: {
      type: Object,
      default: undefined,
    },
    extData: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      targetData: {
        id: null,
        creditClassId: this.extData.creditClassId || this.$route.params.id,
        buildingId: null,
        typeId: null,
        roomId: null,
      },
      required,
    }
  },
  computed: {
    events() {
      return events
    },
    ...mapGetters({
      statuses: `${Attr.store}/statuses`,
      types: `${Attr.store}/statuses`,
      resourceName: `${Attr.store}/resourceName`,
      rooms: 'dropdown/rooms',
      buildings: 'dropdown/buildings',
    }),
    isCreated() {
      return !this.mainData
    },
  },
  methods: {
    ...mapActions({
      createData: `${Attr.store}/createData`,
      updateData: `${Attr.store}/updateData`,
      getRooms: 'dropdown/getRooms',
      getBuildings: 'dropdown/getBuildings',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onBuildingChange(event) {
      this.targetData.roomId = null
      await this.getRooms({ buildingId: event })
    },
    async onShow() {
      this.isLoading = true
      if (this.mainData) {
        this.targetData = this.mainData
      }
      const cUser = getUser()
      const userOrgId = (cUser.orgId ? cUser.orgId : 0)
      await this.getBuildings({ organizationId: userOrgId })
      if (this.isCreated && this.buildings.length > 0) {
        this.targetData.buildingId = this.buildings[0].value
      }
      await this.getRooms({ buildingId: this.targetData.buildingId })
      this.isLoading = false
    },
    onHide() {
      this.$refs
        .creditClassRoomSaveFormRef
        .reset()
      this.targetData = {
        id: null,
        creditClassId: this.extData.creditClassId || this.$route.params.id,
        buildingId: null,
        typeId: null,
        roomId: null,
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .creditClassRoomSaveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isCreated
            ? await this.createData(this.targetData)
            : await this.updateData(this.targetData)
          if (response) {
            const {
              isSuccessful,
              message,
            } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('creditClassRoomSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
